@import '~react-responsive-modal/styles.css';

.cus-modal {
  width: 430px;
  &-wide {
    width: 70vw;
  }
}

.cus-modal h2 {
  margin: 0 0 25px 0;
  color: rgb(163, 163, 163);
  font-size: 18px;
  font-weight: 400;
}

.btn-modal-close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: rgb(35, 35, 35);
}

.cus-modal-actions {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.cus-modal-actions button {
  padding: 8px;
  display: inline-block;
  width: 46%;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: inherit;
  border-radius: 0;
  border-style: none;
}

.cus-modal-actions button:focus {
  outline-style: none;
}

.cus-modal-actions button.secondary {
  background-color: rgb(163, 163, 163);
}

.cus-modal-actions button.primary {
  background-color: rgb(240, 26, 26);
}

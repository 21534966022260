.rme-loading-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.95);
}

.rme-loading-wrapper-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.rme-loading-boxes {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 5;
  .rme-box {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 10px;
    border-radius: 0;
    background-color: rgba(68, 68, 68, 0.05);
  }
}


.rme-loading-animation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 15;
  & > .rme-bounce {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 10px;
    animation: rme-loading-bouncedelay 1.6s infinite ease-in-out both;
    border-radius: 20%;
    background-color: rgba(255, 255, 255, 0.6);
  }
  .rme-bounce1 {
    -webkit-animation-delay: -0.64s;
    animation-delay: -0.64s;
    background-color: rgb(2, 77, 124);
  }
  .rme-bounce2 {
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
    background-color: rgb(122, 201, 67);
  }
  .rme-bounce3 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: rgb(255, 210, 0);
  }
  .rme-bounce4 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: rgb(243, 109, 27);
  }
  .rme-bounce5 {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
    background-color: rgb(126, 191, 224);
  }
}

@keyframes rme-loading-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.home-info {
  display : flex;
  & > div {
    height        : 50px;
    line-height   : 50px;
    border-top    : 1px solid #A3A3A3;
    border-bottom : 1px solid #A3A3A3;
  }
  & > div:nth-child(1) {
    flex        : 0 1 250px;
    font-weight : bold;  
  }
  & > div:nth-child(2) {
    flex         : 1 0 auto;
    background   : #F2F2F2;
    padding-left : 25px;
  }
}

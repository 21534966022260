:root {
  --text-font                : "Open Sans",sans-serif;
  --header-font              : "Hind", sans-serif;
  --main-color               : rgb(2,33,55);          /* #022137 */
  --main-color-text          : #EEE;
  --light-color              : rgb(110,168,217);      /* #6EA8D9 */
  --light-color-faded        : rgb(110,168,217,0.3);
  --light-color-text         : #FFF;
  --contrast-color           : rgb(216,132,44);       /* #D8842C */
  --contrast-color-faded     : rgba(216,132,44,0.3);
  --contrast-color-text      : #FFF;
  --error-color              : rgb(137,70,0);
  --page-section-padding     : 3vw;
}

body, html {
  height: 100%;
}

body {
  margin                  : 0; 
  background              : white;
  font-family             : 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
  color                   : #232323;
}

*,*:before,*:after {
  box-sizing : border-box;
}

input {
  font-family : inherit;
  font-size   : inherit;
}

a {
  color : inherit;
}

div#app-root {
  height : 100%;
}

/* prevent spinners on number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; }

.clickable {
  cursor      : pointer;
  cursor      : hand;
  user-select : none;
}

input:focus,
textarea:focus {
  outline-style: none;
}

.page-title {
  color: #6B6B6B;
  font-size: 18px;
  max-width: 700px;
}

.action-button {
  margin-left      : 5px; 
  margin-right     : 5px;
  padding          : 8px;
  width            : 140px;
  display          : inline-block;
  position         : relative;
  background-color : #FFF;
  text-align       : center;
  color            : white;
  border-radius    : 0;
  cursor           : pointer;
  cursor           : hand;
  -webkit-touch-callout : none;
  -webkit-user-select   : none;
  -khtml-user-select    : none;
  -moz-user-select      : none;
  -ms-user-select       : none;
  user-select           : none; 
}
.action-button.cancel {
  background-color: #A3A3A3;
}
.action-button.save {
  background-color: #7AC943;
}
.action-button.create {
  padding-left     : 45px;
  padding-right    : 20px;
  width            : auto;
  background-color : #F36E1B;
}
.action-button.clear {
  color            : #232323;
  background-color : transparent;
}

.action-button .cross-wrapper {
  position         : absolute;
  top              : 4px;
  left             : 12px;
  display          : inline-block;
}

.action-button .cross {
  margin   : 7px;
  width    : 15px;
  height   : 15px;
  display  : inline-block;
  position : relative;
}

.action-button .cross:before {
  content    : '';
  width      : 100%;
  height     : 1px;
  position   : absolute;
  top        : 7px;
  left       : 0;
  background : rgb(255, 255, 255);
}

.action-button .cross:after {
  content    : '';
  width      : 1px;
  height     : 100%;
  position   : absolute;
  top        : 0;
  left       : 7px;
  background : rgb(255, 255, 255);
}

textarea {
  font-family: inherit;
  font-size: inherit;
  border: none;
  overflow-y: hidden;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  padding-top: 5px;
  padding-bottom: 5px;
}

.page-header-area {
  margin-bottom: 10px;
}
.form-row {
  display: flex;
  margin-bottom: 10px;
  align-items:flex-start;
  align-content:flex-start;
}
.form-row.inline {
  display: inline-flex;
  margin-right: 30px;
  width: 100%;
}
.form-row > div {
  height: 40px;
}
.form-row .form-field-name {
  padding-top   : 10px;
  padding-right : 25px;
  flex          : 0 0 250px;
  font-weight   : bold;
  color         : var(--main-color);
}
.form-row.locked .form-field-name,
.form-row.locked .form-field-value {
  color         : red;
}
.form-row .form-field-name.section {
  font-size: 125%;
  padding-top: 10px;
}
.page-title .footnote,
.form-row .form-field-name .footnote {
  padding-top: 2px;
  font-size: 90%;
  font-weight: normal;
  color: #A3A3A3;
  font-style: italic;
  line-height: normal;
}

.page-title .footnote {
  margin-top: 4px;
}

.footnote-p {
  margin-top: 6px;
  margin-bottom: 6px;
}

.footnote-note {
  margin-top: 30px;
}

.form-row .form-field-value {
  flex: 1 0 260px;
  max-width: 750px;
  border: 1px solid #DDD;
  border-radius: 0;
  padding: 10px 10px;
  font-family: inherit;
  font-size: inherit;
  height: 42px;
}
.form-row .form-field-value.color {
  flex: none;
  width: 60px;
  margin-right: 6px;
  vertical-align: middle;
}
.form-row .form-field-value.short {
  max-width: 100px;
}
.form-row .form-field-value.narrow,
.form-row .form-field-value.number {
  max-width: 150px;
}
.form-row .form-field-value.long {
  min-height: 100px;
  overflow-y: auto;
  resize: vertical;
}
.form-row .form-field-value.readonly {
  min-height: 40px;
  height: auto;
  border-style: dashed;
  border-color: #A3A3A3;
}
.form-row .form-field-value.readonlyarea {
  min-height: 100px;
  height: auto;
  border-style: dashed;
  border-color: #A3A3A3;
}

.form-row .form-field-colour-group {
  display: block;
  height: auto;
}

.form-section {
  margin-top: 15px;
}
.form-section .form-section-name {
  font-weight      : bold;
  color            : var(--main-color);
  padding          : 10px 0 10px 0;
}
.form-section .form-section-content {
  border-left : 4px solid #EEE;
  padding     : 10px 0 5px 15px;
}

.tbl-head-lnk {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}

.tbl-lnk {
  display: inline-block;
  cursor: pointer;
  color: rgb(87, 155, 189);
  text-decoration: underline;
}

.form-row .form-field-value.select-short {
  max-width: 180px;
  height: auto;
  outline: none;
}

.page-btns {
  flex: 1 0 195px;
  text-align: right;
}

/*
.form-tabs-ribbon {
  display: flex;
  justify-content: space-around;
  height: 50px;
  line-height: 50px;
  margin-bottom: 20px;
}
.form-tabs-ribbon > div {
  width: 100%;
  text-align: center;
  border: 1px solid black;
  border-radius: 8px 8px 0 0;
}
.form-tabs-ribbon > div.open {
  border-bottom: none;
}
*/

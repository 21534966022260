@import '~react-table/react-table.css';

.form-controls {
  margin: 15px 0 0 0;
  font-size: 16px;
}

.form-controls .form-field-name {
  padding-right : 0;
  flex          : 0 0 120px;
}

.form-controls .form-row div {
  height: auto;
}

.ReactTable {
  border-style: none !important;

  .admin-table-image {
    max-width  : 80px;
    max-height : 40px;
    display    : block;
    margin     : 0 auto;
  }

  .rt-thead .rt-th.-sort-asc, 
  .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(2, 77, 123, 0.5) !important;
  }

  .rt-thead .rt-th.-sort-desc, 
  .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(2, 77, 123, 0.5) !important;
  }

  .rt-thead.-header {
    box-shadow: none !important;
    border-bottom: 1px solid rgb(163, 163, 163) !important;
  }

  .-pagination {
    box-shadow: none !important;
    border-top: 1px solid rgb(163, 163, 163) !important;
  }

  .rt-tr .rt-th:not(:last-child),
  .rt-tr .rt-td:not(:last-child) {
    border-right: 1px solid rgb(163, 163, 163) !important;
  }

  .ReactTable div[role='columnheader'] {
    padding: 10px 3px !important;
    font-weight: 700;
    outline-style: none;
    background-color: var(--light-color-faded);
  }

  .ReactTable div[role='gridcell'] {
    padding: 8px 15px !important;
  }

  .-pagination .-previous, 
  .-pagination .-next {
    flex: 0 !important;
  }

  .-pagination .-previous button, 
  .-pagination .-next button {
    position: relative;
    color: rgb(35, 35, 35) !important;
    font-family: inherit;
    background: transparent !important;
  }

  .-pagination .-previous button {
    padding-left: 25px;
  }

  .-pagination .-next button {
    padding-right: 25px;
  }

  .-pagination .-btn:not([disabled]):hover {
    background: transparent !important;
    color: rgb(35, 35, 35) !important;
  }

  .ReactTable select {
    font-family: inherit !important;
  }


}

.react-autosuggest__input {
  width: 100%;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display  : none;
  position : relative;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 100%;
  border: 1px solid #DDD;
  background-color: #FFF;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 5px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #E2E2E2;
}

.visibility-control {
  .visible-on-hover {
    visibility : hidden;
  }
  &:hover .visible-on-hover {
    visibility : visible;
  }
}


.image-items {
  display         : flex;
  flex-wrap       : wrap;

  .image-item {
    flex     : 0 0 150px;
    height   : 200px;
    margin   : 10px 10px 10px 0;

    .image-area {
      position : relative;
      width  : 100%;
      border   : 1px solid #DDD;
      height   : 150px;
    }
    .info-area {
      font-size  : 10px;
      text-align : center;
      padding    : 5px 2px;
      width      : 100%;
      height     : 48px;
    }

    .image-item-options {
      display         : flex;
      flex-direction  : column;
      text-align      : center;
      justify-content : space-around;

      .option > span {
        background    : rgba(0,0,0,0.6);
        color         : white;
        padding       : 5px 10px;
        border-radius : 8px;
      }
    }

  }

  .abs-full {
    position   : absolute;
    top        : 0;
    bottom     : 0;
    left       : 0;
    right      : 0;
    max-height : 100%;
    max-width  : 100%;
    width      : auto;
    height     : auto;
    margin     : auto;
  }

}
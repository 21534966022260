div.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

section.header {
  height: 50px;
  width: 100%;
  line-height: 50px;
  background-color: var(--main-color);
  color: white;
  display: flex;
  align-self: stretch;
  justify-content: space-between;
}
section.header .heading {
  font-size: 20px;
  padding-left: 15px;
}
section.header .controls {
  padding-right: 15px;
}

section.columns {
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  margin: 0 auto;  
  position: relative;
}

div.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
}


.nav-column {
  flex: 0 0 200px;
  color: #FFF;
  background-color: #3D6E90;
  display: flex;
  flex-direction: column;
}
.nav-menu {
  flex: 1 0 auto;
}
.nav-menu ul {
  list-style-type: none;
  padding-left: 20px;
}
.nav-menu ul li {
  margin: 15px 0;
}
.nav-menu ul li a.active {
  font-weight: bold;
}

.nav-column img {
  max-width: 192px;
  width: 100%;
  margin: 10px auto 20px;
  display: block;
}

.copyright-note {
  margin-bottom: 15px;
  display: block;
  opacity: 0.5;
  font-size: 12px;
  text-align: center;
}

.content-column {
  flex: 1 0 auto;
  position: relative;
}

.content-wrapper {
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 20px 20px;
}

span {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none; 
}

a { 
  text-decoration: none;
}

.logo-wrapper {
  margin-bottom: 40px;
  width: 28px;
  height: 84px;
}

.logo-wrapper img {
  width: 100%;
  height: 100%;
}

@media all and (max-width: 600px) {
  section.header {
    font-size: 12px;
  }
  section.header .heading {
    font-size: 14px;
  }
  .nav-column {
    flex: 0 0 100px;
    font-size: 12px;
  }
  .nav-menu ul {
    padding-left: 8px;
  }
}

.form-field-value-multi {
  height          : auto !important;
  width           : 100%;
  max-width       : 750px;
  min-height      : 42px;
}

/* note: this must be its own (non-embedded) class to be usable by the drag-n-drop sort HOC  */
.form-field-value-multi-item {
  display          : flex;
  justify-content  : space-between;
  margin-bottom    : 5px;
  cursor           : pointer;
  cursor           : hand;
  background-color : #DDD;
  user-select      : none;

  .sequence {
    padding     : 5px 0;
    text-align  : center;
    flex        : 0 0 30px;
    font-weight : bold;
    border-right : 1px solid #BBB;
  }
  .title {
    padding : 5px;
    flex    : 1 1 auto;
  }
  .control {
    color            : white;
    background-color : var(--main-color);
    padding          : 5px 0;
    flex             : 0 0 30px;
    text-align       : center;
    cursor           : pointer;
    cursor           : hand;
  }
}

.multi-add-items {
  height     : 70vw;
  max-height : 350px;
  overflow-y : auto;
}

.multi-add-item {
  display     : flex;
  align-items : center;
  height      : 25px;
  margin      : 5px 0;

  .title {
    padding-left: 10px;
  }
  .selector {
    border : 1px solid green;
    width  : 25px;
    height : 100%;
    &.add {
      color            : white;
      background-color : green;
      text-align       : center;
      &:after { content: '\2713'; }
    }
  }
}

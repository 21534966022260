@import '~react-toastify/dist/ReactToastify.min.css';

.cus-toast {
  opacity: 0.95;
  border-radius: 0;
}

.cus-toast.success {
  background: var(--contrast-color);
}

.cus-toast.error {
  background: var(--error-color);
}

.cus-toast-body {
  margin: auto 5px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: inherit;
}
